html {
  scroll-behavior: auto;
}
img {
  max-width: 100%;
}

body {
  font-family: "Roboto", sans-serif;
}

header {
  background-color: #1aa0e6;
}

.clndr_icon_1 .react-date-picker__button {
  opacity: 0;
}

.clndr_icon_1 {
  display: inline-block;
  position: relative;
}
.precaution-modal-height {
  max-height: 510px;
  overflow: auto;
}
.medical_tooltip .tooltip-inner {
  max-width: 350px !important;
  padding: 10px !important;
  white-space: break-spaces;
}
.alert-modal p {
  color: #757e83;
  margin-bottom: 0;
  line-height: 28px;
}
.alert-modal span {
  color: rgb(0, 0, 0);
}
.range-value-area {
  border-top: 1px solid #ebebeb;
  padding: 25px 0;
  margin: 25px auto 0;
}
.range_text {
  font-weight: 600;
  font-size: 16px;
}

.red-box p,
.red-box i {
  color: #fe0000;
}
.ranges-color {
  display: flex;
  justify-content: space-between;
  margin: 20px 0 0;
}
.green-box p,
.green-box i {
  color: #1fb812;
}
.orange-box p,
.orange-box i {
  color: #c7c529;
}
.green-box,
.orange-box,
.red-box {
  width: 33.33%;
}
.imagePreviewmessage {
  width: 400px;
  height: 300px;
  object-fit: contain;
}

.reports_list .users_box {
  min-height: unset;
  padding: 16px 32px 16px 14px;
  cursor: pointer;
}
.item-suggetion {
  background-color: rgb(253, 253, 253) !important;
  padding: 5px !important;
  border-bottom: 1px solid rgb(199, 199, 199);
}
.mic_answer {
  position: absolute;
}
textarea {
  padding: 6px;
}
.padding-left {
  margin-left: 53px;
  width: 80%;
  border-radius: 5px;
}

.map_report .time_date,
.map_report_box .time_date {
  color: #1aa0e6 !important;
}

.notification_sections .users_box.map_report {
  padding: 9px 132px 10px 17px;
}
.notification_sections .users_box.map_report_box {
  padding: 9px 132px 10px 17px;
  /* padding: 9px 132px 17px 17px; */
}
.loader-inner.line-scale {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.error {
  color: red !important;
}

.users_box.map_report span {
  word-break: break-word;
}

.add_1 {
  color: #19a1e7;
  font-weight: 500;
  margin-left: 4px;
  cursor: pointer;
  font-size: 18px;
}

.add_map {
  width: 42px;
  height: 42px;
  background: #e7e6e6;
  border-radius: 100px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #1ea2e3;
  font-size: 17px;
  cursor: pointer;
}

.invite_users_links,
.competition_detail_invite {
  display: flex;
  align-items: center;
}
.invite_users_links {
  justify-content: center;
}
.competition_detail_invite {
  justify-content: end;
}
.unset_pointer {
  cursor: unset !important;
}
.invite_user_image.bg-success img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
}
.card-one .fa-credit-card-alt {
  font-size: 24px;
  color: #1ea2e3;
  margin-left: 10px;
}
.add-card-modal .modal-header {
  padding: 15px !important;
}
.add-new-card {
  background: #1ea2e3 !important;
  border-radius: 60px;
  border: none !important;
}
.modal-title.h4 {
  font-size: 18px;
}
.save-card-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.card-details {
  text-align: left;
}
.toggle_row .fa-angle-right {
  font-size: 22px;
}
.card-one .fa-trash-o {
  font-size: 21px;
  color: #a5a5a5;
  cursor: pointer;
}
.card-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px;
  border-bottom: 1px solid #e5e5e5;
  cursor: pointer;
}
.card-details button {
  width: 100px;
  border-radius: 40px;
  margin: 0 auto;
  display: block;
}
.card-one h5 {
  margin: 0;
  margin: 0 20px;
}
.bg-blue-box {
  background: #26b4ef;
  padding: 20px 0 4px;
  color: #fff;
}
.bg-blue-box h3 {
  font-weight: 400;
  font-size: 26px;
}
.thumb_video {
  max-width: 100%;
}
.image-checkbox-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.border-box {
  border-bottom: 1px solid #e9e9e9;
  margin: 0;
  padding: 10px 0;
  cursor: pointer;
}
.border-box:last-child {
  border-bottom: none !important;
}
.scroll-box {
  max-height: 363px;
  overflow: auto;
}
.pointer-events-none {
  pointer-events: none;
}
.image-username img {
  width: 70px;
  min-width: 70px;
  height: 70px;
  min-height: 70px;
  border-radius: 100%;
  object-fit: cover;
  margin-right: 10px;
}
.modal_video {
  max-width: 100%;
  width: 100%;
  height: 300px;
}
.modal_image {
  width: 100%;
  height: 300px;
  object-fit: contain;
}
.thumb_video,
.comp_ad_media {
  width: 100%;
  height: 100px;
  object-fit: cover;
}

.invite_user_btn {
  width: 48px;
  height: 48px;
}

.invite_user_btn img {
  border-radius: 50%;
  width: 42px;
  height: 42px;
}

.play-box {
  position: relative;
}
.play-box i.fa.fa-play,
.play-box i.fa.fa-pause {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -50%);
  background: #c5c5c5;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  color: #000000;
  cursor: pointer !important;
}
.play-box video {
  cursor: pointer;
}
.invite_user_count {
  background-color: white;
  color: #1ea2e3;
  border: 5px solid #1ea2e3;
}

.invite_user_count,
.login_user_count {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login_user_count {
  background-color: white;
  color: #1ea2e3;
  border: 5px solid rgb(26 230 32);
}
.images-all {
  display: flex;
  align-items: center;
  justify-content: center;
}
.select-image {
  border: 2px solid #014875 !important;
}

.heatmap_subtitle {
  color: #345161;
  font-size: 19px;
}

.edit_report {
  position: absolute;
  right: 10px;
  top: 24px;
  font-size: 14px;
  cursor: pointer;
  transform: translate(0px, -50%);
  color: #19a1e7 !important;
  background: #19a1e729;
  padding: 5px 13px;
  border-radius: 100px;
  font-weight: 400;
}

.del_report {
  position: absolute;
  right: 10px;
  top: 70px;
  font-size: 14px;
  cursor: pointer;
  transform: translate(0px, -50%);
  color: red !important;
  background: #19a1e729;
  padding: 5px 13px;
  border-radius: 100px;
  font-weight: 400;
}

.clndr_icon_1 span img {
  position: absolute;
  right: 15px;
  pointer-events: none;
  top: 49%;
  transform: translate(0, -50%);
}

.img_row11 {
  padding: 10px 10px 0 76px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.c-pointer {
  cursor: pointer;
}

.page-btn {
  background-color: #1aa0e6;
  color: white;
  border: none;
}

.group-box.group-box-new {
  padding: 8px 12px 8px 10px;
  height: 78px;
}

.group-box.group-box-new .group-heading {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.img_row11 .group-shot img {
  width: 60px;
  height: 60px;
  min-width: 60px;
  border-radius: 100%;
  object-fit: cover;
}

.img_row11 .group-shot {
  min-width: unset;
  object-fit: cover;
  width: unset;
  margin-right: 8px;
  height: unset;
  overflow: hidden;
  border-radius: unset;
}

.img_row11 .group-shot {
  position: absolute;
  left: 0;
  top: 0;
}

.nom_circle {
  width: 35px;
  display: inline-block;
  border-radius: 100px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #1aa0e6;
  color: #1aa0e6;
  background-color: white;
  font-size: 17px;
  position: absolute;
  top: 17px;
  left: 16px;
}

.symtomps_list .listrow {
  background-color: #e8f5fd;
  padding: 22px 211px 22px 62px;
  position: relative;
}

.symtomps_list .listrow p {
  color: black;
  font-size: 16px;
  margin-bottom: 0;
  padding-bottom: 0;
}

.record_btn {
  background-color: #65d5bc;
  border-radius: 100px;
  padding: 9px 18px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: 13px;
  right: 10px;
}

.record_btn:hover {
  color: white;
}

.prevqstn_btn,
.nextqstn_btn {
  font-size: 16px;
  color: #1aa0e6;
  border: 1px solid #1aa0e6;
  padding: 9px 24px;
  border-radius: 100px;
  min-width: 190px;
}

.prevqstn_btn:hover,
.nextqstn_btn:hover {
  color: #1aa0e6;
}

.sub_btn {
  font-size: 16px;
  color: #fff;
  background-color: #1aa0e6;
  border: 1px solid #1aa0e6;
  padding: 9px 24px;
  border-radius: 100px;
  min-width: 190px;
}

.sub_btn:hover {
  color: white;
}

.btm_button button {
  margin: 0 7px 0 0;
}

.tryagin_btn,
.stop_btn {
  background-color: #1aa0e6;
  border-radius: 100px;
  padding: 9px 18px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  position: absolute;
  top: 13px;
  right: 10px;
}

.tryagin_btn:hover {
  color: white;
}

.tryagin_btn,
.stop_btn,
.symtomps_list.active .record_btn,
.symtomps_list.stop .record_btn,
.symtomps_list.active .stop_btn {
  display: none;
}

.symtomps_list.active .tryagin_btn {
  display: inline-block;
}

.symtomps_list.stop .stop_btn {
  display: inline-block;
}

.disablestart {
  pointer-events: none;
  background-color: #8c8e8f;
}

.answer_box {
  background-color: white;
  border: 1px solid #0000001f;
  padding: 11px;
  border-radius: 5px;
  display: none;
  position: relative;
}

.symtomps_list.active .answer_box {
  display: block;
}

.symtomps_list.stop .answer_box {
  display: block;
}

.answer_box h6 {
  font-size: 14px;
  color: #aaaaaa;
  margin-bottom: 3px;
}

.answer_box p {
  font-size: 15px;
  color: #000000;
  line-height: 1.7;
  padding-bottom: 0;
  margin-bottom: 5px;
}

.thnkutext {
  color: #1aa0e6;
  font-size: 19px;
  font-weight: 600;
}

.headerclose .close {
  font-size: 30px;
  color: #1aa0e6;
  opacity: 1;
}

.headerclose .close:focus {
  outline: none;
  opacity: 1;
}

.headerclose {
  position: absolute;
  right: 5px;
  z-index: 1025;
  padding: 9px;
  top: -1px;
}

.stop.active .tryagin_btn {
  display: none;
}

.check1_box {
  position: relative;
  z-index: 1025;
  margin-top: 5px;
}

.type_box {
  padding-left: 7px;
  margin-top: 5px;
}

.type_box .input100 {
  width: 100%;
  min-height: 80px;
  border-radius: 7px;
  padding: 4px 10px;
  font-size: 15px;
  border: 1px solid #80808036;
  background: #e8f5fd;
}

.type_box .input100:focus {
  outline: none;
}

.check1_box label {
  display: flex;
  /* align-items: center; */
}

header .dropdown-toggle::after {
  color: white;
}

.username_row1 h6 {
  width: 85px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.count{
  position: absolute;
  right: -7px;
  top: -6px;
}
.counting{
  margin-left: 5px;
}
.count,.counting {
  width: 19px;
  height: 19px;
  background-color: #65d5bc;
  color: white;
  border-radius: 100px;
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.dropdown-item:focus,
.dropdown-item.active {
  background: #1aa0e6 !important;
}

.toggle_row {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 9px;
}

.toggle_row h5 {
  color: #345161;
  font-size: 17px;
  margin-bottom: 0;
}

.toggle_row .custom-switch .custom-control-label::before {
  width: 45px;
  border-radius: 100px;
  height: 23px;
}

.toggle_row .custom-switch .custom-control-label::after {
  top: calc(0.25rem + 5px);
  left: calc(-2.25rem + 6px);
  width: calc(1rem - 3px);
  height: calc(1rem - 3px);
}

.toggle_row .custom-control-input:checked ~ .custom-control-label::after {
  left: calc(-1.7rem + 6px);
}

.toggle_row .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #1aa0e6;
  background-color: #1aa0e6;
}

.toggle_row
  .custom-control-input:focus:not(:checked)
  ~ .custom-control-label::before {
  border: 1px solid #ced4da;
}

.toggle_row .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none;
}

.toggle_row .custom-control {
  margin-top: -1px;
}

.switch-row {
  background-color: #fff;
  background-clip: padding-box;
  border-bottom: 1px solid #ced4da;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 9px;
}

.switch-row h5 {
  color: #345161;
  font-size: 17px;
  margin-bottom: 0;
}

.live_1 {
  color: #1aa0e6;
  margin-bottom: 0;
}

.btnsumit:hover {
  background-color: #15d8ad !important;
  color: white !important;
}

.startendrow {
  text-align: right;
}

.startendrow h6 {
  margin-bottom: 0;
}

.startenddate span {
  color: #a2a9ac;
  font-weight: 400;
}

.bodyvital_section .changetext {
  color: #345161;
  font-size: 22px;
}

.setting_form label {
  color: #345161;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 5px;
}

.loginheader .dropdown a {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item.active {
  color: white;
}

.username_row1 {
  display: inline-flex;
  align-items: center;
  position: relative;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.user_dropdown {
  width: 45px;
  height: 45px;
  text-align: center;
  display: inline-block;
  overflow: hidden;
  border-radius: 100px;
  border: 2px solid white;
  background-color: white;
}

.check1_box input[type="radio"] {
  height: 20px;
  min-width: 20px;
  margin: 0;
  margin-top: 2px;
  margin-right: 8px;
  padding: 0;
  opacity: 1;
  cursor: pointer;
  border: 2px solid #868686;
  border-radius: 100px;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
}

.grrenbox_1 {
  background-color: #00b050;
  min-height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.right_side .grrenbox_1 h2,
.right_side .yellowbox_1 h2,
.right_side .redbox1 h2 {
  font-size: 20px;
  font-weight: 400;
  color: white;
}

.symptoms img {
  width: 100px;
  height: 100px;
  margin-bottom: 21px;
}

.yellowbox_1 {
  background-color: #edd609;
  min-height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.redbox1 {
  background-color: #ff2323;
  min-height: 150px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.check1_box {
  display: flex;
  /* align-items: center; */
  /* flex-wrap: wrap; */
  flex-direction: column;
  margin-top: 30px;
}
button.btn.bg-transparent.brder-0.camerabtn {
  border: 2px solid #1aa0e6;
  padding: 55px 55px;
  border-radius: 80px;
  width: 160px;
}
.check1_box .form-check {
  padding: 0 20px 0 7px;
  cursor: pointer;
}

.check1_box label {
  cursor: pointer;
  margin-bottom: 10px;
}

.check1_box input[type="radio"]:checked:after {
  content: " ";
  border: 1px solid #1aa0e6;
  background: #1aa0e6;
  box-shadow: none;
  background-image: url("../images/icon-check.svg");
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
  background-size: 13px;
  display: inline-block;
  visibility: visible;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  margin-left: -1px;
  margin-top: 0px;
}

.select_answer {
  padding-left: 7px;
}

.check1_box input[type="radio"]:checked {
  border: 0;
  /* background: #fb6466;
	box-shadow: none;
	background-image: url('../images/icon-check.svg');
	background-repeat: no-repeat;
	background-position: center;
	box-shadow: none; */
}

.errors {
  color: red;
}

header .nav-link {
  font-size: 18px;
  color: white;
  white-space: nowrap;
}

header .navbar-light .navbar-nav .nav-link {
  color: white;
  font-size: 17px;
  padding: 0;
  margin: 8px;
}

header .navbar-light .navbar-nav .nav-link:hover {
  color: white;
}

header .login_btn {
  background-color: white;
  border-radius: 100px;
  padding: 7px 20px;
  color: #1aa0e6;
  margin-left: 0.5rem;
  font-size: 18px;
}

header .Register_btn {
  background-color: #65d5bc;
  border-radius: 100px;
  padding: 7px 20px;
  color: #fff;
  font-size: 18px;
  margin-left: 0.5rem;
}

footer {
  background: #1aa0e6;
}

footer .list-group-item {
  position: relative;
  padding: 0 15px;
}

footer .list-group-item:before {
  position: absolute;
  right: 0;
  top: 0;
  content: "";
  width: 1px;
  height: 100%;
  z-index: 1024;
  background-color: white;
}

footer .list-group-item:last-child:before,
footer .list-group-item:first-child:before {
  background-color: transparent;
}

.footer_social_links .list-group-item:before {
  content: unset;
}

.footer_social_links .list-group-item a {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  background-color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

footer .footer_social_links .list-group-item {
  position: relative;
  padding: 0 5px;
}

.login_form {
  border-radius: 10px;
  padding: 30px;
  box-shadow: 1px -1px 16px -9px #1781b8 !important;
  border: 1px solid #d3d3d385;
}

.login_form h4 {
  font-size: 30px;
  color: #1aa0e6;
  text-align: center;
}

.login_form p {
  color: #345161;
  font-size: 17px;
  text-align: center;
}

.login_form h5 {
  color: #345161;
  font-size: 19px;
}

.input103 {
  color: grey;
  border: 2px solid #e5e5e5;
  height: 56px;
  border-radius: 8px;
  padding: 5px 16px;
}

.loginbtn{
  background: #34bd9d;
  font-size: 20px;
}

.invite_user_button{
  background: #1ea2e3;
  font-size: 1em;
}
.invite_user_button:hover{
  color: white;
}

.loginbtn,
.invite_user_button {
  color: white;
  min-width: 180px;
  padding: 10px;
  border-radius: 100px;
}

.modal-content {
  border: 0;
  border-radius: 10px;
}

.backbtn {
  background: #bfbfbf;
  color: white;
  min-width: 180px;
  padding: 10px;
  font-size: 20px;
  box-shadow: none !important;
  border-radius: 100px;
  margin-bottom: 4px;
}

.login_form .text-center .loginbtn.btn.shadow {
  box-shadow: none !important;
}

.dob_Row .react-date-picker {
  width: 100%;
}

.dob_Row .react-date-picker__wrapper {
  color: grey;
  border: 2px solid #e5e5e5;
  height: 56px;
  border-radius: 8px;
  padding: 5px 16px;
  width: 100%;
}

.forgotpassword {
  display: block;
  color: #1aa0e6;
  margin: 20px 0 5px 10px;
  font-size: 18px;
}

.signup_text {
  color: #1aa0e6;
  margin: 20px 0;
  font-size: 18px;
}

.profile_box {
  width: 135px;
  height: 135px;
  border-radius: 100px;
  background: #c4c4c44a;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  align-items: center;
}
.main_profile {
  display: inherit;
}

/* .profile_box img{
    height: 100%;
} */
a:hover {
  text-decoration-line: unset;
}

.clndr3 .react-date-picker__button {
  opacity: 0;
}

.clndr3 .dob_Row {
  position: relative;
}

.clndr3 .location-search-input {
  color: grey;
  border: 2px solid #e5e5e5;
  height: 56px;
  border-radius: 8px;
  padding: 5px 16px;
}

.clndr3 span img {
  position: absolute;
  right: 10px;
  pointer-events: none;
  top: 17px;
}

.prilfe_image .camerabtn {
  position: absolute;
  left: 10%;
  top: 50%;
  transform: translate(-10%, -50%);
}

.prilfe_image .imagebtn {
  position: absolute;
  right: 10%;
  top: 50%;
  transform: translate(-10%, -50%);
}

.loginsec,
.symtomps_section {
  min-height: calc(100vh - 200px);
}

.input103.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #e5e5e5;
  outline: 0;
  box-shadow: none;
}
.profile_box img {
  width: 100%;
  object-fit: cover;
  height: 100%;
}

/**************************************************loader************************************************/
.mic_answer {
  display: flex;
  align-items: start;
}

.show_mic {
  display: flex;
}

.hide_mic {
  display: none;
}

.mic_answer {
  pointer-events: none;
}

.group_detail_scroll{
  max-height: "695px";
  overflow-y: "scroll";
}

@keyframes pulso {
  0% {
    -moz-box-shadow: 0 0 0 0 #1aa0e678;
    box-shadow: 0 0 0 0 #1aa0e678;
  }

  70% {
    -moz-box-shadow: 0 0 0 7px #1aa0e638;
    box-shadow: 0 0 0 7px #1aa0e638;
  }

  100% {
    -moz-box-shadow: 0 0 0 0 #1aa0e61f;
    box-shadow: 0 0 0 0 #1aa0e61f;
  }
}

.pulse .fa.fa-microphone {
  font-size: 15px;
  color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.pulse {
  display: inline-block;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: #1aa0e6;
  cursor: pointer;
  box-shadow: 0 0 0 #1aa0e6;
  animation: pulso 2s infinite;
  position: relative;
}

.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 10px rgba(204, 169, 44, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.gif_bg {
  z-index: 1025;
  margin-right: 18px;
  margin-left: 8px;
  margin-top: 5px;
}

.profile_name1 {
  display: flex;
  align-items: center;
}

.loginheader .profile_1.dropdown a {
  padding: 10px 12px;
  border-bottom: 1px solid #d3d3d352 !important;
}

.loginheader .profile_1.dropdown a:last-child {
  border-bottom: 0;
}

.loginheader .profile_1 .dropdown-menu {
  padding: 0;
  margin-top: 19px !important;
  border-radius: 9px;
  border: 1px solid #d3d3d352;
}

.loginheader .profile_1 .dropdown-menu::before {
  position: absolute;
  top: -14px;
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  left: 10px;
  border-width: 10px 15px 10px 0;
  border-color: transparent #fff transparent transparent;
  transform: rotate(88deg);
}

/* .profile_1 h6{
    position: relative;
}
.profile_1 h6:after{
    background-image: url('../images/dwn1.svg');
    position: absolute;
    right: 11px;
    content: '';
    top: 4px;
    background-size: cover;
    width: 18px;
    height: 11px;
} */
.profile_1 .dropdown-toggle::after {
  content: unset;
}

.loader {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #000000cc;
  top: 0;
  z-index: 1060;
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes line-scale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

@keyframes line-scale {
  0% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }

  50% {
    -webkit-transform: scaley(0.4);
    transform: scaley(0.4);
  }

  100% {
    -webkit-transform: scaley(1);
    transform: scaley(1);
  }
}

.line-scale > div:nth-child(1) {
  -webkit-animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s -0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(2) {
  -webkit-animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s -0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(3) {
  -webkit-animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s -0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(4) {
  -webkit-animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s -0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div:nth-child(5) {
  -webkit-animation: line-scale 1s 0s infinite
    cubic-bezier(0.2, 0.68, 0.18, 1.08);
  animation: line-scale 1s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}

.line-scale > div {
  background-color: #fff;
  width: 6px;
  height: 89px;
  border-radius: 2px;
  margin: 8px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
}

.maintabing_row {
  display: flex;
}

.dashboard_section {
  background-color: #e5e5e5;
  min-height: calc(772px - 40px);
}

.maintabing_row .left_side {
  background-color: white;
  width: 15%;
  text-align: center;
  border-radius: 14px;
  /* overflow: hidden; */
  max-height: 564px;
}

.maintabing_row .right_side {
  width: 80%;
  background-color: white;
  border-radius: 14px;
  margin-left: auto;
  padding: 20px;
  box-shadow: 0px 10px 35px rgba(23, 129, 184, 0.1);
}

/* .doughnut_div canvas {
    width: 420px !important;
    height: 420px !important;
}

.scatter_graph canvas {
    width: 900px !important;
}

.line_graph canvas {
    width: 900px !important;
    height: 380px !important;
} */

.right_side h2 {
  color: #345161;
  font-size: 22px;
  font-weight: 500;
}

.maintabing_row .left_side h3 {
  color: #345161;
  font-size: 17px;
  margin-top: 10px;
}

.maintabing_row .left_side .nav-link {
  padding: 7px 0;
  position: relative;
  /* border-bottom: 1px solid #000000; */
  border-bottom: 2px solid #00000014;
  border-top: 2px solid #fff0;
  border-radius: 0;
  overflow: hidden;
}

.maintabing_row .left_side .nav-item:last-child .nav-link {
  border-bottom: 0;
}

.maintabing_row .left_side .nav-item:first-child .nav-link.active {
  border-radius: 10px 10px 0 0;
  border: 2px solid #1aa0e6;
}

.maintabing_row .left_side .nav-item:last-child .nav-link.active {
  border-radius: 0 0 10px 10px;
  border: 2px solid #1aa0e6;
}

.maintabing_row .left_side .nav-link.active {
  background-color: white;
  border: 2px solid #1aa0e6;
  position: relative;
  z-index: 1025;
  border-radius: 0;
}

.maintabing_row .left_side .nav-link.active::before {
  position: absolute;
  right: -32px;
  top: 50%;
  content: "";
  width: 0;
  height: 0;
  border-left: 13px solid transparent;
  border-right: 18px solid transparent;
  border-bottom: 29px solid #1aa0e6;
  transform: translate(0, -50%) rotate(90deg);
}

.maintabing_row .left_side .nav-link img {
  -webkit-filter: saturate(49%) grayscale(71%) brightness(-18%) contrast(0);
  filter: saturate(49%) grayscale(71%) brightness(-18%) contrast(0);
  opacity: 0.5;
}

.maintabing_row .left_side .nav-link.active img {
  -webkit-filter: unset;
  filter: unset;
  opacity: 1;
}

.maintabing_row .left_side .nav-link.active h3 {
  color: #1aa0e6;
}

.datatype {
  border-radius: 8px;
  border: 1px solid lightgrey;
  padding: 18px 37px 18px 21px;
  position: relative;
  min-height: 100px;
  margin: auto;
  display: flex;
  justify-content: start;
  align-items: center;
}

.datatype span {
  width: 20px;
  height: 20px;
  background-color: #ff2323;
  border-radius: 100px;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0, -50%);
}

.datatype span.green {
  background: green;
}

.rangebox1,
.bmi_box1,
.blood_box1 {
  border-radius: 8px;
  border: 1px solid lightgrey;
  padding: 18px;
  position: relative;
}

.chart_container {
  height: 500px !important;
}

.flex-classs {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rangebox1 .bar1 {
  width: 100%;
  height: 7px;
  background-color: #e1e1e1;
  border-radius: 100px;
  margin-top: 10px;
}

.rangebox1 .bar1.box-red {
  background-color: #ff2323;
}

.rangebox1 .bar1.box-green {
  background-color: #00b050;
}

.rangebox1 .bar1.box-yellow {
  background-color: #edd609;
}

.rangebox1 h6,
.rangebox1 h4 {
  color: #345161;
}

.bmi_box1,
.blood_box1 {
  text-align: center;
  height: 100%;
}

.bmi_box1 h6,
.blood_box1 h6 {
  color: #345161;
}

.bmi_box1 h5 {
  color: #1aa0e6;
}

.blood_box1 h5 {
  color: #ff5069;
}

.Profile_box {
  background-color: #00b05029;
  border-radius: 10px;
  padding: 25px;
  text-align: center;
}

.Profile_box h5 {
  color: #00b050;
}

.Profile_box h4 {
  color: #00b050;
}

.rightside_box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.rightside_box h2 {
  color: white;
  font-size: 20px;
}

.rightside_box .box1 {
  background-color: #ff2323;
  min-height: 150px;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48%;
  border-radius: 10px;
  margin-bottom: 20px;
}

.rightside_box .box1.yellow {
  background-color: #edd609;
}

.rightside_box .box1.box-red {
  background-color: #ff2323;
}

.rightside_box .box1.box-green {
  background-color: #00b050;
}

.rightside_box .box1.box-yellow {
  background-color: #edd609;
}

.rightside_select form div.mb-3 {
  position: relative;
  padding: 0 36px 0 0;
}

.rightside_select form select {
  height: 46px;
  border-radius: 10px;
}

.rightside_select .info-div-one {
  position: absolute;
  right: 0;
  top: 10px;
  display: inline-block;
  cursor: pointer;
}

.bodyvital_section .right_side {
  width: 100%;
  background-color: white;
  border-radius: 14px;
  margin-left: auto;
  padding: 20px;
  box-shadow: 2px 3px 17px -16px #1781b8 !important;
}

.bodyvital_section h2 {
  color: #345161;
  font-size: 24px;
}

.metrics_row h3 {
  color: #1aa0e6;
  font-size: 24px;
}

.metrics_row p {
  color: #345161;
}

.metric_form input {
  height: 46px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px 20px;
}

.btnsumit {
  background-color: #65d5bc;
  border-radius: 100px;
  padding: 12px 20px;
  color: #fff;
  font-size: 17px;
  width: 180px;
}

.group_row .nav-pills {
  border-bottom: 1px solid #80808040;
}

.group_row .nav-pills .nav-link.active {
  color: #345161;
  font-weight: 600;
  border-bottom: 2px solid #1aa0e6;
}

.group_row .nav-pills .nav-link {
  color: #9ea9ae;
  border-radius: 0;
  font-weight: 500;
  font-size: 18px;
  background-color: transparent;
}

.more-prescription {
  color: #1aa0e6;
  font-size: 18px;
}

.metric_form.row h3 {
  font-size: 22px;
  color: #1aa0e6;
  font-weight: 400;
}

.start-d-calender {
  position: absolute;
  right: 26px;
  top: 42px;
  font-size: 18px;
}

.group-box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  display: block;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
}

.group-shot {
  min-width: 80px;
  object-fit: cover;
  width: 80px;
  margin-right: 8px;
  height: 80px;
  overflow: hidden;
  border-radius: 100px;
}

.group-heading {
  color: #345161;
  font-size: 18px;
  margin-bottom: 2px;
}

.group-sub.joined {
  color: #41d22e;
}

.group-sub {
  color: #fe0000;
  font-weight: normal;
  font-size: 16px;
  margin: 0;
}

.inner_box.profile-inner h3 {
  color: #345161;
}

.profile-inner {
  display: flex;
  align-items: center;
}

.downbook-one {
  background: #1aa0e63d;
  padding: 4px 3px;
  border-radius: 10px;
  text-align: center;
  width: 48%;
  font-size: 21px;
  color: #345161;
  border: 3px solid #34c90026;
}

.average-health .nav-item.nav-link {
  border: none;
}

.average-health .nav.nav-tabs {
  border: none;
}

.average-health .nav-item.nav-link.active {
  background: #1aa0e6;
  font-size: 20px;
  color: #fff;
  width: 170px;
  text-align: center;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
}

.profile-image p {
  color: #bdbebe;
  font-size: 18px;
  font-weight: 400;
  margin: 0;
  text-align: left;
}

.average-health h3 {
  font-size: 23px;
  color: #345161;
}

.down-book {
  margin-top: 30px;
}

h4 {
  font-size: 20px;
  color: #345161;
}

.profile-image p span {
  color: #1aa0e6;
  word-break: break-all;
  font-weight: 500;
}

.average-health .nav-item.nav-link {
  background: #e9f6fd;
  font-size: 20px;
  color: #5f5f5f;
  width: 170px;
  text-align: center;
  height: 51px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9px;
}

.downbook-two {
  background: #34c9003b;
  padding: 8px 3px;
  width: 48%;
  text-align: center;
  border-radius: 10px;
  font-size: 21px;
  color: #345161;
  border: 3px solid #34c90026;
  margin-left: 3px;
}

.profile-inner a {
  color: #1aa0e6;
  font-size: 18px;
  margin-left: auto;
  background: #1aa0e624;
  padding: 8px 20px;
  border-radius: 10px;
  font-weight: 500;
}

.date-cart {
  background: #e9fdfb;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 17px 10px;
  margin-top: 21px;
  border-radius: 10px;
  font-size: 20px;
  color: #345161;
  width: 100%;
  border: unset;
}

.drop-cart {
  background: #c112121c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 10px;
  margin-top: 21px;
  border-radius: 10px;
  border: 3px solid #c112121c;
  width: 100%;
}

.metric_form select {
  height: 46px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px 20px;
  font-size: 18px;
  font-weight: 400;
}

.timing-box li:last-child {
  margin-right: 0 !important;
}

.timing-box {
  margin: 0;
  padding: 0;
}

.timing-box li {
  display: inline-table;
  background: #ebeef0;
  height: 14px;
  line-height: 36px;
  border-radius: 5px;
  padding: 0 4px;
  font-size: 15px;
  margin-bottom: 2px;
  margin-right: 4px;
}

.timing-box {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 5px;
}

.timing-box li {
  min-width: 78px;
  text-align: center;
  display: inline-table;
  background: #ebeef0;
  height: 14px;
  line-height: 36px;
  border-radius: 5px;
  padding: 0 4px;
  font-size: 15px;
  margin-right: 4px;
  margin-bottom: 2px;
}

.prescription-delete {
  display: flex;
  align-items: center;
}

.prescription-delete h4 {
  color: #fe0000 !important;
  font-size: 18px !important;
  margin-left: auto;
  cursor: pointer;
}

.medical-history-box a {
  width: 220px;
}

.timing-box .active {
  background: #1aa0e6;
  color: #fff;
}

.precaution-symptoms-bt {
  border-left: 1px solid #00000024;
}

span.normal-text {
  color: #fe0000;
}

.precaution-bt {
  color: #65d5bc;
  font-size: 16px;
  text-decoration: revert;
  cursor: pointer;
}

.symptoms-bt {
  color: #1aa0e6;
  font-size: 15px;
  text-decoration: revert;
  cursor: pointer;
}

.precaution-symptoms-bt p {
  border-bottom: 1px solid #dbdbdb;
  height: 40px;
  margin: 0;
  line-height: 0;
  padding: 30px 0;
}

.precaution-modal {
  padding: 0;
  text-align: left;
}

.precaution-modal-main p {
  font-size: 18px;
  line-height: 23px;
}

.precaution-modal-main li {
  font-size: 18px;
  display: flex;
}

.medical-record-page h3 {
  margin-bottom: 23px;
}

.precaution-modal i {
  color: #65d5bc;
}

button:focus {
  outline: none;
}

.precaution-modal {
  list-style: none;
}

.medical-his-area {
  padding: 20px;
}

.precaution-symptoms-bt p:last-child {
  border: none;
}

.medical-his-content {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
}

.prescription-box.medical-history-box {
  margin-bottom: 30px;
}

.medical-his-content {
  border: 1px solid #dbdbdb;
}

.medical-his-area h5 {
  color: #345161;
}

.medical-his-area p {
  margin: 0;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: none;
  outline: 0;
  box-shadow: none;
  border: 1px solid #e5e5e5;
}

.metric_form.row h4 {
  font-size: 16px;
  color: #345161;
}

.inner_box {
  width: 100%;
  background-color: white;
  border-radius: 14px;
  margin-left: auto;
  padding: 20px;
  box-shadow: 0px 10px 35px rgb(23 129 184 / 10%);
  min-height: 635px;
}

.bodyvital_section {
  background-color: #e5e5e5;
}

.bodyvitals_head h6 {
  color: #345161;
}

.bodyvitals_head .react-date-picker__wrapper {
  border: 1px solid lightgrey;
  border-radius: 7px;
  padding: 6px 8px;
  color: #345161;
}

.react-date-picker__button svg.react-date-picker__clear-button__icon {
  display: none !important;
}

.react-date-picker__inputGroup input {
  color: #345161;
  font-weight: 500;
}

.align-item-center h5 {
  color: #345161;
  font-size: 16px;
}

.rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
  border-top-color: #ff5069 !important;
}

.mood-range-slider h4 {
  margin-left: -15px;
}

.price_slider {
  margin-top: 53px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.rc-slider-tooltip-inner {
  font-size: 12px !important;
  align-items: center;
  justify-content: center;
  background-color: #ff5069 !important;
  display: flex;
  min-width: 32px !important;
  cursor: pointer;
  height: 30px !important;
}

.rc-slider-handle:hover {
  border-color: #ffffff !important;
}

.rc-slider-handle:active {
  border-color: #ffffff !important;
}

.rc-slider-handle {
  width: 18px;
  height: 18px;
  box-shadow: 0 0 5px #ff5069;
  border: solid 2px #fff;
  background-color: #ff5069;
}

.rc-slider-track {
  height: 9px;
  background-color: #ff5069 !important;
}

.systolic-box {
  position: absolute;
  top: -20px;
  right: -223px;
  max-width: 310px;
  width: 210px;
  display: none;
  z-index: 1;
}

.systolic-box .d-flex {
  justify-content: space-between;
}

.systolic-box p {
  font-size: 13px;
}

.systolic-box h6.text-center {
  margin: 0;
  background: #41d22e;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  padding: 7px 0;
}

.systolic-box p {
  color: #fff;
  margin: 0;
}

.rightside_select .info-div-one:hover .systolic-box {
  display: inline-block;
}

.systolic-one {
  border-radius: 5px 5px 0 0;
}

.alert-last {
  border-radius: 0 0 5px 5px;
}

.alert-one {
  background: #fc0000;
  padding: 2px 10px;
}

.good-one {
  background: #41d22e;
  padding: 2px 10px;
}

.caution-one {
  background: #c7c429;
  padding: 2px 10px;
}

.rc-slider-rail {
  background-color: #e1e1e1;
  height: 9px;
}

.rc-slider-step {
  height: 9px;
}

.mood-range-slider {
  border: 1px solid #e5e5e5;
  padding: 24px 30px;
  border-radius: 10px;
  margin-bottom: 30px;
}

.rc-slider-track {
  height: 9px;
  background-color: #ff5069;
}

text.CircularProgressbar-text {
  font-size: 18px !important;
  font-weight: 600;
}

.add-description {
  margin-left: auto;
  /* width: 200px; */
}

.prescription-box {
  display: flex;
  align-items: center;
}

.prescription-week h2 {
  font-size: 18px;
  border-bottom: 1px solid #0000000f;
  padding: 14px 0;
}

.prescription-all-progress span {
  color: #1aa0e6;
  font-size: 22px;
  font-weight: 600;
  border: 2px solid #1aa0e6;
  width: 30px !important;
  height: 30px;
  border-radius: 50%;
  padding: 0 7px;
  margin-right: 14px;
}

.prescription-all-progress {
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.medi-time {
  display: flex;
  align-items: center;
}

.medi-time img {
  margin-right: 19px;
}

.medi-time-text h5 {
  font-size: 16px;
  color: #1aa0e6;
  font-weight: 400 !important;
}

.medi-time-text h6 {
  color: #345161;
  font-size: 16px;
  font-weight: 400;
}

.medi-time {
  margin-bottom: 10px;
  cursor: pointer;
}

.new-medi-box {
  display: flex;
  align-items: center;
}

.new-medi-box h6 {
  margin-bottom: 0;
}

.new-medi-box i.fa.fa-check {
  position: absolute;
  right: 30px;
  color: #1aa0e6;
  font-size: 20px;
}

.second-medi-time img {
  margin-left: 4px;
  margin-right: 24px;
}

.profile-icon {
  background: #ffffff;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  color: #1aa0e6;
}

.navbar-nav {
  display: flex;
  align-items: center;
}

.upload-profile-pic {
  width: 160px;
  position: absolute;
  left: 50%;
  top: 80px;
  transform: translate(-50%, -50%);
  height: 160px;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
}

.login_btn {
  cursor: pointer;
}

.custom-form-file {
  position: absolute;
  width: 31px !important;
  padding: 0 !important;
  left: 0;
  opacity: 0;
}

.send_message_row {
  width: 100%;
  border-top: 1px solid lightgrey;
  position: relative;
}

.no_chat_box {
  /* min-height: 400px; */
  /* display: flex; */
  justify-content: center;
  align-items: center;
  width: 100%;
}

.user_img {
  min-width: 60px;
  height: 60px;
  width: 60px;
  overflow: hidden;
  border-radius: 100px;
  margin-top: 7px;
}

.leftside_chat {
  display: flex;
  align-items: start;
}

.user_msg {
  background: #1aa0e6;
  border-radius: 7px;
  padding: 12px 21px 12px 12px;
  color: white;
  font-size: 15px;
  margin-bottom: 2px;
  position: relative;
  overflow-wrap: anywhere;
}

.user_msg:before {
  position: absolute;
  left: -9px;
  content: "";
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #1aa0e6 transparent transparent;
}

.user_name_p h5 {
  font-size: 17px;
  color: #345161;
}

.user_name_p {
  margin-left: 15px;
}

.msg_time {
  color: #9ea9ae;
  font-size: 14px;
  text-align: right;
  margin-bottom: 5px;
  margin-left: 12px;
  margin-top: 10px;
}

.right {
  width: 40px;
  height: 40px;
  border-radius: 100px;
  min-width: 40px;
}

.leftside_chat.right_side_user {
  justify-content: flex-end;
}

.leftside_chat.right_side_user .user_msg {
  background-color: #345161;
}

.leftside_chat.right_side_user .user_msg:before {
  right: -4px;
  border-color: transparent #345161 transparent transparent;
  transform: rotate(62deg);
  left: unset;
}

.chat_box {
  height: calc(635px - 196px);
  padding: 0 12px;
  overflow: auto;
}

.user_image_row1 {
  min-width: 120px;
  width: 120px;
  height: 120px;
  overflow: hidden;
  border-radius: 100px;
  text-align: center;
  display: inline-block;
}

.user_image_row1 > img {
  width: 100%;
  max-width: 100%;
  height: 100%;
  object-fit: cover;
}

.add_create_group_input span {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: 10px;
}

.gorup_Details_head h5 {
  display: inline-block;
  font-size: 17px;
  color: #345161;
  margin-left: 20px;
}

.create_group select {
  height: 46px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 100%;
  display: block;
  padding: 10px 20px;
}

.gorup_Details_head h5 span {
  color: #1aa0e6;
}

.Description_row h5 {
  font-size: 17px;
  color: #345161;
}

.Description_row p {
  font-size: 16px;
  color: #345161;
  line-height: 1.7;
  text-align: justify;
  line-break: anywhere;
}

.gorup_Details_head.group_list_row1 .user_image_row1 {
  min-width: 70px;
  width: 70px;
  height: 70px;
  overflow: hidden;
  border-radius: 100px;
  text-align: center;
  display: inline-block;
}

.legend_box1 {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #f6e4a9;
  display: inline-block;
}

textarea:focus {
  outline: none;
}

.create_group_checkbox .form-check-input {
  width: 17px;
  height: 17px;
}

.form-check-label {
  color: #345161;
  font-size: 15px !important;
  line-height: 1.7;
  padding-left: 7px;
}

.chart_head select {
  font-size: 15px;
}

.group_list_row1 label {
  font-size: 16px;
  color: #345161;
  font-weight: 500;
}

.group_list_row1 input {
  height: 46px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px 20px;
}

.group_list_row1 p {
  color: #345161;
  font-size: 15px;
}

.group_list_row1 {
  border-left: 1px solid #d3d3d370;
  padding-left: 20px;
}

.user_image_row1.user_image_row1_upload_image {
  background-color: #c4c4c4;
  border: 1px solid #ababab;
  position: relative;
  cursor: pointer;
  width: 151px;
  height: 151px;
}

.user_image_row1.user_image_row1_upload_image i {
  font-size: 55px;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  pointer-events: none;
  cursor: pointer;
  transform: translate(-50%, -50%);
}

.check-chk {
  display: flex;
  align-items: center;
  justify-content: end;
}

.chk {
  height: 20px !important;
  width: 20px !important;
  margin-left: 14px;
}

.gorup_Details_head.grup_chat_head {
  margin: auto;
  display: block;
  text-align: center;
}

.create_group input,
.create_group textarea {
  height: 46px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  width: 100%;
  display: block;
  padding: 10px 20px;
}

.create_group label {
  font-size: 16px;
  color: #345161;
  font-weight: 500;
}

.create_group textarea {
  height: unset;
}

.user_image_row1.user_image_row1_upload_image input {
  cursor: pointer;
  width: 100%;
  height: 100%;
  border-radius: 100px;
  opacity: 0;
}

.chart_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}

.chart_head h5 {
  color: #345161;
  font-size: 16px;
}

.legend_Row {
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  color: #345161;
  margin: 0 10px;
}

.legend_box2 {
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background-color: #1aa0e6;
  display: inline-block;
}

.gorup_Details_head,
.group_list_row2 {
  display: flex;
  align-items: center;
}

.group_list_row2 {
  padding: 14px 0;
  border-bottom: 1px solid #d3d3d370;
}

.group_list_row2 span {
  color: #345161;
  font-size: 16px;
  margin-left: 12px;
  font-weight: 500;
  word-break: break-all;
}

.send_message_row input,
.send_message_row textarea {
  width: 100%;
  height: 55px;
  border: 0;
  word-break: break-all;
}

.send_message_row input {
  /* padding: 0 53px; */
  padding: 0px 70px 0px 50px;
}

.send_message_row textarea {
  padding: 22px 50px 0px 45px;
}

.send_message_row input:focus,
.send_message_row textarea:focus {
  outline: none;
}

.send_message_row span:first-child {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translate(0, -50%);
}

.send_message_row button:last-child,
.send_message_row span:last-child {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
}

.no_chat_box {
  height: calc(635px - 196px);
  display: flex;
  justify-content: center;
}

.chat_row {
  width: 100%;
}
.chat_search_box {
  padding-right: 45px !important;
}
.nav_active_link {
  color: #007bff !important;
}
.nav_in_active_link {
  color: #495057 !important;
}

.search_bar_row {
  position: relative;
}
.selected_file_section {
  background: #ededed;
  /* height: 90px; */
  padding: 5px;
}
.selected_file_section i {
  top: -4px;
  font-size: 12px;
  color: #fff;
  background: red;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -6px;
  cursor: pointer;
}
.selected_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: #dfd4d4;
}
.message-image {
  width: 250px;
  height: 250px;
  object-fit: contain;
}
.selected_image {
  width: 100px;
  height: 100px;
  position: relative;
  padding: 5px;
}

.search_bar_row button,
.search_bar_row span {
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translate(0, -50%);
  opacity: 0.6;
}

.search_bar_row input {
  height: 46px;
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px;
  font-size: 15px;
  font-weight: 400;
  color: #345161;
}

.users_box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 14px 38px 12px 73px;
  position: relative;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  color: #345161;
  font-weight: 500;
}

.users_box .invite_user_image {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translate(0px, -50%);
  width: 49px;
  height: 49px;
  display: inline-flex;
  justify-content: center;
  background: #eeeeee;
  overflow: hidden;
  align-items: center;
  border-radius: 100px;
}

.users_box .invite_btn1 {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translate(0px, -50%);
}

.users_box .invite_user_image img {
  height: 100%;
  object-fit: cover;
  /* rgb (83, 83, 83); */
}

.users_box .btnsumit {
  background-color: #65d5bc;
  border-radius: 100px;
  padding: 7px 20px;
  font-size: 16px;
  width: unset;
}
.search-form input {
  border: 1px solid #d7d7d7 !important;
  border-radius: 50px 0 0 50px;
  height: 35px;
  width: 100%;
  padding: 0 15px;
}
.search-form {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 17px;
}
  .search-bt button {
  background: #5296df;
  border: none;
  height: 35px;
  border-radius: 0 50px 50px 0;
  padding: 0 11px;
  color: #fff;
}
.search-bt button:disabled{
  opacity: 0.65;
}
#referral_code {
  display: flex;
  justify-content: center;
  font-size: 14px;
  position: relative;
}
#referral_code i.fa.fa-copy {
  position: absolute;
  right: 0;
  font-size: 20px;
}
.users_box .btnsumit.invited_btn {
  padding: 7px 8px 7px 20px;
}

.competition_row {
  align-items: start;
}

.maintabing_row.competition_row .left_side .nav-link {
  padding: 28px 0;
}
.copy-refrel {
  justify-content: left !important;
}
.copy-bt {
  position: absolute;
  right: 0;
  top: 12px;
}
.play-bt-vid {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 35px;
  color: #fff;
  background: #2c9acd;
  border-radius: 50%;
  height: 29px;
  line-height: 30px;
  cursor: pointer !important;
}

.maintabing_row.competition_row .users_box {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 14px 12px 88px;
  position: relative;
  min-height: 90px;
  display: block;
  align-items: center;
  font-size: 18px;
  color: #345161;
  font-weight: 500;
}

.maintabing_row.competition_row .users_box .invite_user_image {
  width: 65px;
  height: 65px;
  transform: unset;
  top: 10px;
}

.maintabing_row.competition_row .users_box p {
  color: #34516187;
  font-size: 15px;
  margin-bottom: 5px;
}

.maintabing_row.competition_row .users_box p span {
  color: #1aa0e6;
}

.invite_text {
  color: #1aa0e6;
  font-size: 18px;
}

.comp_details_area h2,
.points_section h2 {
  color: #345161;
  font-size: 17px;
}

.comp_details_head p {
  color: #34516187;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 2px;
}

.comp_details_head span {
  color: #1aa0e6;
}

.comp_details_area .details_label {
  background-color: #e1e1e161;
  padding: 2px;
  display: flex;
  align-items: start;
  padding: 13px 10px;
  justify-content: space-between;
}

.comp_details_area .details_label h3:first-child {
  font-size: 16px;
  color: #1aa0e6;
}

.comp_details_area .details_label h3:last-child {
  font-size: 15px;
  color: #345161;
}

.points_box {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  box-shadow: 0px 10px 35px rgba(23, 129, 184, 0.1);
  border-radius: 10px;
}

.points_box h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  margin-bottom: 2px;
  color: #345161;
  padding: 14px 0px 14px 11px;
  font-weight: 500;
}

.points_box .minimum_points {
  font-size: 15px;
  margin-bottom: 2px;
  color: #1aa0e6;
  font-weight: 500;
}

.points_box h5 {
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  text-align: right;
  color: #252525;
  padding: 14px 9px 14px 11px;
  margin-bottom: 0;
  opacity: 0.5;
}

.points_box p {
  font-size: 14px;
  margin-bottom: 2px;
  color: #1aa0e6;
  padding-left: 9px;
  font-weight: 500;
}

.details_rightside h3 {
  font-size: 16px;
  margin-bottom: 2px;
  color: #345161;
}

.nextarrow {
  font-size: 30px;
  color: #1aa0e6;
  font-weight: 800;
}

.fa-copy {
  font-size: 25px;
  color: #1aa0e6;
  cursor: pointer;
  font-weight: 500;
}

.refral_row i {
  /* position: absolute;
    top: 0;
    right: 0; */
}

.users_box.leaderboard_box .invite_user_image {
  background-color: #34bd9d;
  color: white;
  font-size: 23px;
  font-weight: 600;
}

.amount_leader {
  color: #949494 !important;
}

.newrqst1 {
  font-size: 16px;
  color: #345161;
  font-weight: 500;
  cursor: pointer;
}
.view-image,
.view-error-image {
  position: relative;
  width: 200px;
  object-fit: cover;
}

.view-image {
  height: 200px;
}

.view-error-image {
  height: auto;
}
.play-box-bt {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  transform: translate(-50%, -50%);
}

.friend_sections .nextarrow {
  font-size: 30px;
  color: #9ea9ae;
  font-weight: 800;
}

.friend_sections .users_box {
  padding: 14px 36px 12px 73px;
}

.accpt_reject_section .inner_box {
  /* background-image: url('../images/bg_img.png'); */
  background-image: url("../images/send-request-bg.png");
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.chatlist_section .msg1 {
  color: #838383;
  font-weight: 400;
  font-size: 14px;
  display: block;
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.online1 {
  position: absolute;
  left: 42px;
  top: 9px;
  width: 15px;
  height: 15px;
  background-color: #00c136;
  border-radius: 100px;
  border: 2px solid white;
}

.ofine1 {
  position: absolute;
  left: 42px;
  top: 9px;
  width: 15px;
  height: 15px;
  background-color: #c10000;
  border-radius: 100px;
  border: 2px solid white;
}
.competition_detail_invite button {
  padding: 2px !important;
}

.notification_sections .users_box {
  padding: 4px 36px 4px 17px;
  cursor: pointer;
  font-size: 17px;
  min-height: unset;
  height: 100%;
}

.notification_sections .users_box p {
  color: #25252580;
  display: flex;
  align-items: center;
  margin-top: 3px;
  margin-bottom: 0;
  font-size: 14px;
}

.chat_time {
  color: #83838382;
  font-weight: 400;
  font-size: 15px;
  display: block;
}

.chatlist_section .users_box {
  padding: 14px 87px 12px 73px;
}

.accptbtn {
  background-color: #65d5bc;
  border-radius: 100px;
  padding: 12px 20px;
  color: #fff;
  font-size: 17px;
  text-align: center;
  margin: auto;
  width: 226px;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
}

.accpt_reject_section h3 {
  font-size: 20px;
  color: #345161;
}

.accpt_reject_section h5 {
  color: #345161;
  font-size: 15px;
}

.Rejectbtn {
  background-color: #676767;
  border-radius: 100px;
  padding: 12px 20px;
  color: #fff;
  font-size: 17px;
  width: 226px;
}

.frndrqst_img {
  width: 150px;
  height: 150px;
  border-radius: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.frndrqst_img img {
  height: 100%;
}

.notification_modal {
  text-align: center;
}

.notification_modal h5 {
  font-size: 23px;
  color: #1aa0e6;
  margin-bottom: 2px;
}

.notification_modal p {
  font-size: 17px;
  color: #1aa0e6;
}

.modal_img_row {
  width: 100px;
  height: 100px;
  border-radius: 7px;
  display: flex;
  align-items: center;
  border: 1px solid #c4c4c4;
  overflow: hidden;
  justify-content: center;
  text-align: center;
}

#reactgooglegraph-5 {
  width: 100% !important;
}

.profile_tab .nav-link {
  background: #e9f6fd;
  border-radius: 0px;
  padding: 8px 20px;
}

.profile_tab .nav-link.active {
  background: #1aa0e6;
  border-radius: 9px;
}

.fitbtn.active {
  color: white;
  background: #1aa0e6;
}

.fitbtn {
  margin: auto;
  background: rgba(26, 160, 230, 0.14);
  border-radius: 100px;
  width: 278px;
  display: block;
  margin-bottom: 10px;
  font-size: 15px;
  padding: 15px;
  color: #1aa0e6;
  font-weight: 500;
}

.skip {
  color: #007bff;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
}

/* .profile_tab .nav-link.active:first-child{
    border-radius: 9px;
} */
.sharebtn {
  background-color: #65d5bc;
  border-radius: 100px;
  padding: 12px 20px;
  color: #fff;
  font-size: 17px;
  text-align: center;
  margin: auto;
  width: 226px;
  display: block;
  margin-top: 10px;
  margin-bottom: 5px;
}

.inner_collapse .collapse {
  height: 0;
  opacity: 0;
  visibility: hidden;
  /* display: block; */
  border-bottom: 1px solid #eaeaea;
}

.inner_collapse .collapse.show {
  transition: 0.3s ease-in all;
  opacity: 1;
  height: auto;
  visibility: visible;
  border-bottom: 1px solid #eaeaea;
}

.clndr2 .react-date-picker__wrapper {
  height: 46px;
  border-radius: 10px;
  border: 1px solid #e5e5e5;
}

.clndr2 .react-date-picker__button {
  opacity: 0;
}

.clndr2 .form-group {
  position: relative;
}

.clndr2 span img {
  position: absolute;
  right: 13px;
  pointer-events: none;
  top: 12px;
}

.clndr2 input {
  padding: 10px 7px;
  height: unset !important;
  border: 0 !important;
  border-radius: 0 !important;
  outline: 0 !important;
}

.clndr2 .react-date-picker {
  width: 100%;
}

.clndr2 .react-date-picker__inputGroup__input:invalid {
  background: transparent;
  outline: 0;
  border: 0;
}

.bodyvitals_head span {
  cursor: pointer;
}

.timing-box {
  cursor: pointer;
}

.box-red {
  background-color: #ff2323;
}

.box-green {
  background-color: #00b050;
}

.box-yellow {
  background-color: #edd609;
}

.user_dropdown img {
  width: 45px;
  height: 45px;
  object-fit: cover;
}

.gooddata {
  color: #00d700;
}

.cautiondata {
  color: #c7c500;
}

.alertdata {
  color: #ff0000;
}

#container {
  height: 400px;
}

.highcharts-figure,
.highcharts-data-table table {
  min-width: 320px;
  max-width: 800px;
  margin: 1em auto;
}

.highcharts-data-table table {
  font-family: Verdana, sans-serif;
  border-collapse: collapse;
  border: 1px solid #ebebeb;
  margin: 10px auto;
  text-align: center;
  width: 100%;
  max-width: 500px;
}

.highcharts-data-table caption {
  padding: 1em 0;
  font-size: 1.2em;
  color: #555;
}

.highcharts-data-table th {
  font-weight: 600;
  padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
  padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
  background: #f8f8f8;
}

.highcharts-data-table tr:hover {
  background: #f1f7ff;
}

/* canvas {
    border: 1px dotted red;
} */

.chart-container {
  position: relative;
  margin: auto;
  height: 80vh;
  width: 80vw;
}

.down-book {
  display: flex;
  align-items: start;
  justify-content: space-between;
}
.user-details td,
.user-details th {
  border-top: initial !important;
  border-bottom: initial !important;
}
.user-details td {
  border-top: initial !important;
}
/* .user-details td{
    color: ;
} */
.recorded-data {
  border: 1px solid #dee2e6;
  border-radius: 12px;
}
.recorded-data thead th {
  border-bottom: initial;
  border-top: initial;
  border-left: 1px solid #c3c3c3;
}
.recorded-data td {
  border-left: 1px solid #c3c3c3;
}
.date-format {
  width: 172px !important;
}
.recorded-data td:first-child,
.recorded-data th:first-child {
  border-left: initial;
}
.precaution-data {
  padding: 0;
}
.precaution-data .precaution-symptoms-bt {
  border-left: 0px solid #00000024;
}
.users_box-friend img {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}
.users_box-friend {
  border-radius: 10px;
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  padding: 13px;
  justify-content: space-between;
}
.users_box-friend button {
  width: fit-content;
  padding: 8px 25px;
}
.centered-pie-chart {
  text-align: center;
  justify-content: center;
}
span.good-score {
  /* color: rgb(60, 188, 165); */
  margin-left: 5px;
}
td.digit-score,
span.digit-score {
  color: #767676;
}
.percentage-box {
  position: relative;
}
.bodyvital_section .percentage-txt {
  font-size: 40px;
  position: absolute;
  top: 45%;
  left: 30%;
  font-weight: 900;
}

.active_menu {
  position: relative;
}
.active_menu:after {
  content: "";
  position: absolute;
  background: white;
  height: 4px;
  width: 100%;
  bottom: -7px;
  left: 0;
}
/* membership-plan */
.mastership-plan {
  padding: 50px 0;
}
.mastership-plan .nav-tabs .nav-link {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-shadow: 0px 10px 35px rgba(23, 129, 184, 0.1);
  border-radius: 10px;
  color: #1aa0e6;
  font-weight: 500;
  padding: 14px 0;
  margin: 0 10px 0 10px;
}
.mastership-plan .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 1px solid #1aa0e6;
}
.mastership-plan .nav-tabs {
  border-bottom: none;
}
.membership-tab-content,
.business-license-contact-area {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 30px;
  margin-top: 30px;
}
.membership-top-content {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 25px;
}
.membership-top-right-content {
  margin-left: 15px;
}
.membership-top-right-content h5 {
  color: #1aa0e6;
  font-size: 25px;
  margin-bottom: 0;
}
.membership-top-right-content p {
  margin-bottom: 0;
  color: #345161;
  font-size: 15px;
}
.membership-tab-content ul {
  padding-left: 25px;
  list-style: none;
  padding-top: 10px;
}
.membership-tab-content ul li {
  color: #345161;
  font-size: 16px;
  padding: 17px 0 5px 15px;
  position: relative;
}
.membership-tab-content ul li:after {
  content: "";
  background: #a3d9f5;
  width: 15px;
  height: 15px;
  position: absolute;
  left: -20px;
  top: 21px;
  border-radius: 50%;
}
.consumer-left,
.consumer-right {
  width: 50%;
}
.consumer-area {
  border-bottom: 1px solid #e5e5e5;
}
.monthly-yearly-btn {
  margin-top: 20px;
  display: flex;
}
.active-button-section{
  border: 1px solid #1aa0e6 !important;
}
.monthly-btn {
  margin-right: 10px;
}
.monthly-btn,.yearly-btn {
  border: 1px solid #e5e5e5;
}
.monthly-btn,.yearly-btn{
  border-radius: 10px;
  padding: 23px 40px;
  height: 80px;
  position: relative;
}
.active-btn{
  cursor: pointer;
}
.monthly-btn h6,
.yearly-btn h6 {
  margin-bottom: 0;
  color: #345161;
}
.monthly-btn h6 span,
.yearly-btn h6 span {
  color: #ababab;
  font-size: 15px;
  font-weight: 400;
  margin-left: 7px;
}
.monthly-btn h6 img,
.yearly-btn h6 img {
  margin-right: 10px;
}
.yearly-btn p {
  color: #345161;
  font-size: 13px;
  margin-bottom: 0;
}
.purchase-btn button {
  background: #34bd9d;
  border: none;
  color: #fff;
  padding: 14px 65px;
  font-size: 18px;
  border-radius: 32px;
  margin-top: 28px;
}

.purchase-btn {
  text-align: center;
}
.affiliate-area {
  border-bottom: 1px solid #e5e5e5;
  padding-top: 18px;
}
.affiliate-area p img {
  margin-right: 15px;
}
.business-license-contact-area h2 {
  color: #1aa0e6;
  font-size: 26px;
  text-align: center;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 20px;
}
.business-license-contact-area label {
  color: #345161;
  font-weight: 500;
}
.business-license-contact-area form {
  padding-top: 40px;
}
.business-license-contact-area form button {
  margin: 0 auto;
  display: block;
  background: #34bd9d;
  color: #fff;
  font-size: 18px;
  padding: 12px 70px;
  border-radius: 33px;
  margin-top: 41px;
}
.business-license-contact-area form .form-control {
  padding: 25px 15px;
}

.active-buisness{
  background-color: #34C900;
}
.pending-buisness{
  background-color:#F3C317;
}
.expired-buisness{
  background-color:#F63030;
}
.rejected-buisness{
  background-color: #F63030;
}
.buisness-license-status{
  border-radius: 30px;
  color: #fff !important;
  padding: 2px 6px;
  font-size: 13px;
  border: unset;
}
.subscription-plan-box {
  border-radius: 20px;
  border: 2px solid #e7e7e7;
  padding: 15px;
  margin-top: 20px;
}
.subscription-plan-box h6 {
  font-size: 14px;
  color: #7D7D7D;
  opacity: 0.8;
  font-weight: 500;
}
.subscription-name h4 {
  font-size: 18px;
  font-weight: 400;
}
.subscription-name {
  display: flex;
  align-items: end;
  justify-content: space-between;
  padding: 5px 0 0;
}
.subscription-name .year-payment {
  color: #7D7D7D;
  /* opacity: 0.5; */
}
.upgrade-paln-btn {
  background: #34bd9d;
  border-radius: 30px;
  color: #fff !important;
  padding: 7px 23px;
  font-size: 16px;
}
.user_img.right img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.profile-badge-header {
  position: absolute;
  width: 32px !important;
  left: 22px;
  height: 20px !important;
  object-fit: contain !important;
  bottom: -5px;
}
.profile-badge {
  width: 50px;
  position: absolute;
  top: 150px;
  right: 85px;
}
.profile-image {
  position: relative;
}
.profile-badge {
  width: 40px;
  position: absolute;
  bottom: 30px;
}
.subscription-plan-box hr {
  margin: 2px 0 14px;
}
.monthly-btn-disable{
  opacity: 0.7;
}
.actual-price{
  color: #ECECEC;
  text-decoration: line-through;
}
.nav-tabs .nav-link.disabled{
  color: #6c757d !important;
  background-color: transparent !important;
  border-color: transparent !important;
  box-shadow: unset;
  border: 1px solid #6c757d!important;
}
.disable-tab{
  color: gray;
    opacity: 0.7;
}
.user-profile-new {
  width: 200px;
  height: 200px;
  object-fit: cover;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #ededed;
}
.info-icon {
  font-size: 18px;
  position: absolute;
  right: 4px;
  top: 5px;
  color: #1ea2e3;
}

/* toggle css */
.renew-box .switch {
  position: relative;
  display: inline-block;
  width: 74px;
  height: 34px;
  margin-bottom: 0;
}

.renew-box .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.renew-box .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.renew-box .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.renew-box input:checked + .slider {
  background-color: #1ea2e3;
}

.renew-box input:focus + .slider {
  box-shadow: 0 0 1px #1ea2e3;
}

.renew-box input:checked + .slider:before {
  -webkit-transform: translateX(39px);
  -ms-transform: translateX(39px);
  transform: translateX(39px);
}

/* Rounded sliders */
.renew-box .slider.round {
  border-radius: 34px;
}

.renew-box .slider.round:before {
  border-radius: 50%;
}
.business-card-border {
  border: 1px solid #E5E5E5;
  padding: 22px;
  /* text-align: center; */
  border-radius: 10px;
}
.btn-progress {
  color: #1FB812;
  border: 1px solid #1FB812;
  padding: 12px;
  border-radius: 42px;
  background: #1fb81217;
}
.centered-element{
  width: 100%;
  text-align: center;
}
.business-card-border p {
  color: #345161;
  text-align: center;
}

.accept_reject_btn{
  padding: 5px 20px;
  border: 0;
  border-radius: 32px;
  margin: 0 4px;
  background: white;
}
.accept_btn{
  color: #1FB812;
}
.accept_border{
  border: 1px solid #1FB812;
}
.reject_border{
  border: 1px solid #ef0707;
}
.reject_btn{
  color: #ef0707;
}
.reject {
  background: #02ff0e;
}
.user-invite-section {
  background: #19a1e729;
  padding: 10px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.user-invite img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.user-invite .user_image_row1 {
  width: 60px;
  height: 60px;
  min-width: 60px;
}
.user-invite {
  border: 1px solid #e5e5e5;
  border-radius: 10px;
  padding: 10px;
}
.precaution-modal-main p {
  font-size: 16px;
  line-height: 24px;
  padding: 9px 0;
  margin: 0;
}
.company-box{
  border-bottom: 1px solid #e9e9e9;
}
.company-main {
  max-height: 312px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
}
.company-box-img {
  margin-right: 22px;
}
.profile-img{
  position: relative;
}


/********************************************************************************************************/
/* Responsive starts section here */
@media (max-width: 1232px) {
  .navbar-brand {
    width: 199px;
  }
}

@media (max-width: 1199px) {
  .chart_container {
    height: 450px !important;
  }
  .profile-badge {
      width: 40px;
      position: absolute;
      top: 64%;
      right: 33px;
  }

  footer .list-group-item {
    position: relative;
    padding: 0 7px;
    font-size: 15px;
  }

  .systolic-box {
    position: absolute;
    top: 30px;
    right: 0;
  }

  .downbook-one {
    padding: 3px 3px;
  }

  .downbook-two,
  .downbook-one {
    font-size: 18px;
  }

  .timing-box li {
    min-width: 60px;
    font-size: 14px;
    text-align: center;
  }

  .maintabing_row .left_side,
  .maintabing_row .right_side {
    width: 100%;
    margin-bottom: 10px;
  }

  .maintabing_row {
    flex-direction: column;
  }

  .maintabing_row .left_side .nav-link.active,
  .maintabing_row .left_side .nav-item:last-child .nav-link.active {
    border-radius: 10px 10px 0 0;
  }

  .maintabing_row .left_side .flex-column.nav.nav-pills {
    flex-direction: row !important;
  }

  .maintabing_row .left_side .flex-column.nav.nav-pills .nav-item {
    border: 1px solid lightgrey;
    width: 25%;
  }

  .datatype span {
    width: 13px;
    height: 13px;
  }

  .bodyvital_section h2,
  .right_side h2 {
    font-size: 18px;
  }

  .maintabing_row.competition_row .users_box {
    font-size: 15px;
  }

  .searchfrnd_input {
    min-width: 220px !important;
  }

  .newrqst1,
  .maintabing_row.competition_row .users_box p {
    font-size: 14px;
  }

  .users_box .btnsumit {
    padding: 7px 17px;
    font-size: 14px;
  }

  .users_box {
    font-size: 16px;
  }

  .group-heading {
    color: #345161;
    font-size: 16px;
    margin-bottom: 2px;
  }

  header .navbar-light .navbar-nav .nav-link {
    color: white;
    font-size: 15px;
  }

  .group-shot {
    min-width: 62px;
    object-fit: cover;
    width: 62px;
    margin-right: 8px;
    height: 62px;
    overflow: hidden;
    border-radius: 100px;
  }

  header .login_btn {
    background-color: white;
    border-radius: 100px;
    padding: 7px 16px;
    color: #1aa0e6;
    margin-left: 0.5rem;
    font-size: 16px;
    display: inline-block;
  }

  header .Register_btn {
    font-size: 16px;
  }
  .bodyvital_section .percentage-txt {
    font-size: 30px;
    top: 40%;
    left: 32%;
  }
}

@media (max-width: 991px) {
  /* .loginheader .navbar-toggler{
        display: none;
    } */
  /* .doughnut_div canvas {
            width: 360px !important;
            height: 360px !important;
        }
    
        .scatter_graph canvas {
            width: 900px !important;
        }
    
        .line_graph canvas {
            width: 700px !important;
            height: 380px !important;
        } */

  .forheader1 {
    text-align: center;
  }
  .profile-badge {
    right: 19px;
  }
  .maintabing_row .left_side .flex-column.nav.nav-pills .nav-item {
      width: 20%;
  }
  .leftside_tabing img {
      width: 30px;
  }
   .footer_social_links .list-group-item a {
      width: 30px;
      height: 30px;
    }
    .footer_social_links img {
        width: 14px;
    }
  .maintabing_row .left_side .nav-link {
      height: 92px;
  }
  .maintabing_row .left_side h3 {
      font-size: 15px;
  }
  .bodyvital_section h2, .right_side h2 {
      margin-bottom: 15px !important;
      margin-bottom: 0 !important;
  }
  .prescription-box .btnsumit {
      width: initial;
      display: block;
  }
  .navbar-nav {
      align-items: flex-end;
  }

  footer .list-group-item {
    position: relative;
    padding: 0 7px;
    font-size: 13px;
  }

  .downbook-two {
    margin-left: 0;
  }

  .down-book {
    flex-direction: column;
  }

  .downbook-two,
  .downbook-one {
    width: 100%;
    margin-bottom: 4px;
  }

  .loginheader .profile_1 .dropdown-menu {
    margin-top: 8px !important;
  }

  .loginheader .profile_1 .dropdown-menu::before {
    content: unset;
  }

  .chat3 {
    width: 90px !important;
    height: 90px !important;
  }

  .chatlist_section .msg1 {
    width: 212px;
  }

  .friend_sections .nextarrow {
    font-size: 24px;
  }

  .container {
    max-width: 856px;
  }

  .points_box h4 {
    padding: 14px 0px 0px 11px;
  }

  .points_box p {
    padding-bottom: 0 !important;
    margin-bottom: 0;
  }

  .comp_details_area .details_label {
    margin-bottom: 9px;
  }

  .details_rightside {
    margin-top: 20px;
  }

  .user_image_row1.user_image_row1_upload_image {
    width: 119px;
    height: 119px;
  }
  .maintabing_row.competition_row .left_side .nav-link {
      padding: 18px 10px;
  }

  .users_box .btnsumit {
    padding: 6px 12px;
    font-size: 13px;
  }

  .users_box {
    font-size: 15px;
    padding: 9px 138px 9px 66px;
    min-height: 65px;
  }

  .users_box .invite_user_image {
    width: 42px;
    height: 42px;
  }
  .bodyvital_section .percentage-txt {
    font-size: 24px;
    top: 40%;
    left: 35%;
  }
  .navbar-toggler {
      background: #fff;
  }
  .login-header .navbar-toggler {
      display: none;
  }
  .login-btn{
    display: flex;
    flex-direction: row;
  }
  .monthly-btn, .yearly-btn {
      padding: 23px 14px;
  }
}

.fit-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

@media (max-width: 767px) {
  /* .doughnut_div canvas {
        width: 420px !important;
        height: 420px !important;
    }

    .scatter_graph canvas {
        width: 900px !important;
    }

    .line_graph canvas {
        width: 700px !important;
        height: 380px !important;
    } */
  .my-friend-head {
    display: inline-block !important;
    text-align: center;
  }
  .profile-badge {
    right: 0;
  }
  .monthly-btn, .yearly-btn {
      padding: 23px 14px;
  }
  .rangebox1 h6, .rangebox1 h4 {
      color: #345161;
      font-size: 15px;
  }
  .rangebox1, .bmi_box1, .blood_box1 {
      padding: 9px;
  }
  .bodyvital_section h2, .right_side h2 {
      font-size: 15px !important;
  }
  .prescription-week {
      margin-top: 10px;
  }
  .clndr3_1 .react-date-picker__wrapper {
      margin-left: 9px;
  }
  .card-header:first-child {
      padding: 0 !important;
  }
  .prescription-all-progress span {
      font-size: 18px;
  }
  .friend-head-one {
    margin: 5px 0;
  }
  .friend-head-one {
    display: inline-block;
  }
  .metric_form input,
  .rightside_select form select {
    font-size: 14px;
    padding: 10px 9px;
  }

  .list-group {
    flex-wrap: wrap;
  }

  .container {
    max-width: 728px;
  }

  h2 {
    font-size: 19px !important;
  }

  .record_btn,
  .symtomps_list.active .tryagin_btn,
  .tryagin_btn,
  .stop_btn {
    position: unset;
    padding: 8px 20px;
    margin-top: 8px;
    font-size: 14px;
    /* display: inline-block; */
    margin-right: 7px;
  }
  .btn.bg-transparent.brder-0.camerabtn {
      position: inherit;
      left: 0 !important;
      top: 60px !important;
  }

  .symtomps_list .listrow {
    padding: 22px 10px 22px 62px;
  }
}

.mob_mob1 {
  display: none;
}

.clndr3_1 .react-date-picker__wrapper {
  height: 44px;
  border: thin solid #8080806b;
}

.btnsumit {
  margin-bottom: 20px;
}

.social_icons_1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.points_details h4 {
  font-size: 20px;
  font-weight: 600;
  color: #1aa0e6;
}

.points_details p {
  font-size: 16px;
  color: #345161;
}

.points_details p span {
  font-weight: 600;
  margin-top: 7px;
}

.blue_box {
  background: #f0f0f0;
  padding: 23px 0;
}

.widthtext {
  width: 70%;
  margin: auto;
}

.accptinvitation_btn .btn {
  width: 250px;
  font-size: 18px;
}

.prixeimg_box {
  display: inline-block;
  background: #f0f0f0;
  padding: 24px;
  border-radius: 10px;
}

.prize_images {
  display: flex;
  align-items: center;
  justify-content: center;
}


@media (max-width: 575px) {
  /* .doughnut_div canvas {
        margin-top: 15px;
        width: 320px !important;
        height: 320px !important;
    }

    .scatter_graph canvas {
        margin-top: 15px;
        width: 320px !important;
    }

    .line_graph canvas {
        margin-top: 15px;
        width: 320px !important;
        height: 200px !important;
    } */

  .prixeimg_box {
    padding: 12px;
  }
  .profile-img {
      width: 130px;
      margin: 0 auto;
  }
  .prilfe_image {
      margin: 13px 0 0 0  !important;
  }
  .create_group label {
      font-size: 13px;
  }
  .inner_box .mt-4.row {
      margin-top: 0 !important;
  }
  .create_group input, .create_group textarea, .create_group select {
      height: 43px;
      font-size: 12px;
  }
  .form-check-label {
      font-size: 12px !important;
  }
  .css-14el2xx-placeholder {
      font-size: 13px;
  }

  .points_details p span {
    font-size: 14px;
  }

  .widthtext {
    width: 100%;
    margin: auto;
  }

  .startendrow {
    text-align: left;
    margin-top: 13px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d3d3d359;
    padding-top: 14px;
  }

  .startendrow h6 {
    font-size: 14px;
    margin-top: 0 !important;
  }

  .metrics_row.metrics_row1 .col-9 {
    max-width: 55%;
    min-width: 55%;
  }

  .metrics_row.metrics_row1 .col-3 {
    max-width: 45%;
    min-width: 45%;
  }

  .info-div-one img {
    width: 17px;
  }

  .mobrow_2 .col-6 {
    max-width: 50%;
    min-width: 50%;
  }

  .metric_form .form-group,
  .rightside_select form div.mb-3 {
    margin-bottom: 10px !important;
  }

  .rightside_select form div.mb-3 .form-group {
    margin-bottom: 0 !important;
  }

  .rightside_select form div.mb-3 {
    position: relative;
    padding: 0 24px 0 0;
  }

  .metric_form input,
  .rightside_select form select {
    font-size: 12px;
    padding: 8px 0 8px 15px;
  }

  .metrics_row .col-9 {
    max-width: 65%;
    min-width: 65%;
  }

  .metrics_row .col-3 {
    max-width: 35%;
    padding: 0 2px;
    min-width: 35%;
  }

  .loginsec,
  .symtomps_section {
    min-height: calc(500px - 200px);
  }

  /* .web_mob1 {
    display: none;
  } */

  .mob_mob1 {
    display: block;
  }

  .average-health svg {
    width: 100% !important;
  }

  footer .list-group-item:before {
    background-color: transparent;
  }

  footer .list-group-item {
    border: 0 !important;
  }

  .firstfooter_list .list-group-item {
    width: 100%;
    margin-bottom: 10px;
  }

  .footer_social_links .list-group {
    justify-content: center !important;
  }
  .btnsumit {
      padding: 7px 20px;
      font-size: 14px;
  }

  .firstlistgroup {
    width: 100%;
    text-align: center;
  }

  .notification_sections .nextarrow {
    display: none;
  }

  .maintabing_row .left_side .flex-column.nav.nav-pills .nav-item {
    border: 1px solid lightgrey;
    width: 50%;
  }

  .medical-history-box a {
    width: 220px;
  }

  .chatlist_section .users_box {
    padding: 14px 11px 12px 73px;
  }

  .chatlist_section .msg1 {
    width: 100%;
  }

  .friend_sections .searchfrnd_input {
    min-width: 100% !important;
    margin-bottom: 10px !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
  }

  .friend_sections .users_box .invite_btn1 {
    position: absolute;
    transform: translate(0px, -50%);
  }

  .friend_sections .users_box {
    min-height: 87px;
  }

  .points_box h5 {
    text-align: left;
    padding-top: 4px;
  }

  .users_box .btnsumit {
    padding: 6px 10px;
    font-size: 13px;
    min-width: 100px;
    margin-top: 5px;
  }

  .users_box .invite_user_image {
    transform: unset;
    top: 10px;
  }
  .users_box, .user_name_p h5 {
    font-size: 13px;
  }
  .chatlist_section .msg1, .user_msg {
      font-size: 12px;
  }
  .user_msg {
      padding: 5px 21px 5px 12px;
  }
  .msg_time {
      font-size: 12px;
      margin-top: 4px;
  }
  .c-pointer .fa {
      padding: 17px 19px !important;
  }
  .send_message_row input, .send_message_row textarea {
      height: 51px;
      font-size: 12px;
  }
  .yearly-btn p {
      font-size: 10px;
      margin-left: 33px;
  }
  .monthly-btn h6, .yearly-btn h6 {
      font-size: 12px;
  }
  .info-icon {
      font-size: 13px;
  }
  .monthly-btn, .yearly-btn {
      padding: 8px 32px;
      height: 68px;
  }
/* 
  .users_box .invite_btn1 {
    position: unset;
    transform: unset;
  } */
  .nextarrow {
      font-size: 20px;
  }
  .users_box {
    display: block;
    min-height: unset;
    margin-bottom: 10px !important;
  }
  header .login_btn, header .Register_btn {
      padding: 2px 16px;
      font-size: 13px;
  }
  .login_form {
      margin: 0 !important;
  }
  .login_form h4 {
      font-size: 23px;
  }
  .login_form p {
      font-size: 13px;
      margin: 5px 0;
  }
  .login_form form {
      margin: 11px !important;
  }
  .input103, .dob_Row .react-date-picker__wrapper {
      height: 48px;
      font-size: 12px;
      margin-bottom: 1px !important;
  }
  .loginbtn, .invite_user_button, .backbtn {
      padding: 7px;
      font-size: 15px;
      margin: 3px 1px !important;
  }
  .forgotpassword, .signup_text {
      font-size: 12px;
  }
  .profile_box {
      width: 105px;
      height: 105px;
  }
  .errors {
      font-size: 11px;
      font-style: italic;
      letter-spacing: 1px;
  }
  .dob_Row {
      margin-bottom: 13px;
  }
  .login_form {
      padding: 19px;
  }
  .form-label {
    font-size: 12px;
    color: #345161;
    font-weight: 500;
}
  .text-left .form-group {
    margin-bottom: 0 !important;
  }
  .mastership-plan {
      padding: 13px 0;
  }
  .mastership-plan .nav-tabs .nav-link {
      font-size: 13px;
      padding: 6px 0;
      margin: 0 5px 0 10px;
  }
  .membership-tab-content, .business-license-contact-area {
      margin-top: 0px;
      padding: 15px;
  }
  .membership-top-right-content h5 {
      color: #1aa0e6;
      font-size: 20px;
      margin-bottom: 0;
  }
  .membership-top-content {
      padding-bottom: 12px;
  }
  .membership-top-right-content p {
      font-size: 12px;
  }
  .membership-tab-content ul li {
      font-size: 12px;
      padding: 11px 0 5px 15px;
  }
  .membership-tab-content ul li:after {
      width: 9px;
      height: 9px;
      left: -10px;
      top: 16px;
  }
  .purchase-btn button {
      padding: 6px 37px;
      font-size: 13px;
      margin-top: 13px;
  }
  .footer_social_links .list-group-item a {
    width: 30px;
    height: 30px;
  }
  .footer_social_links img {
      width: 14px;
  }
  .info-icon {
      right: 14px;
      top: 11px;
  }
  .monthly-btn h6, .yearly-btn h6 {
      font-size: 16px;
  }
  .monthly-btn h6 span, .yearly-btn h6 span {
      margin-left: 2px;
      font-size: 12px;
  }
  .yearly-btn p {
      font-size: 12px;
  }
  .monthly-yearly-btn {
      margin-top: 9px;
  }
  .business-license-contact-area form {
      padding-top: 0;
  }
  .business-license-contact-area form .form-control {
      padding: 7px 15px;
      font-size: 12px;
  }
  .business-license-contact-area form button {
      color: #fff !important;
      font-size: 12px;
      padding: 7px 38px;
      margin-top: 21px;
  }
  .maintabing_row .left_side .flex-column.nav.nav-pills .nav-item {
      width: 20%;
  }
  .leftside_tabing img {
      width: 24px;
  }
  .maintabing_row .left_side .nav-link {
      height: 83px;
      padding: 10px 12px;
  }
  .maintabing_row .left_side h3 {
      font-size: 11px;
  }
  .datatype {
      padding: 5px 44px 5px 44px;
      min-height: 69px;
  }
  .rangebox1 h6, .rangebox1 h4 {
      color: #345161;
      font-size: 12px;
  }
  .bmi_box1 h6, .blood_box1 h6 {
      color: #345161;
      font-size: 12px;
  }
  .bmi_box1 h5 {
      font-size: 15px;
  }
  .Profile_box {
      padding: 10px;
  }
  .rightside_box .box1 {
      min-height: 90px;
  }
  .bodyvital_section h2, .right_side h2 {
      font-size: 13px !important;
  }
  .Profile_box h4, .Profile_box h5{
      font-size: 12px;
  } 
  .prescription-box .btnsumit {
      font-size: 12px;
      padding: 7px 19px;
  }
  .prescription-all-progress p {
      font-size: 12px;
  }
  .prescription-all-progress span {
      font-size: 11px;
      padding: 4px 7px;
      border: 1px solid #1aa0e6;
  }
  .prescription-all-progress p {
      margin: 10px 0 !important;
  }
  p {
      font-size: 13px;
  }
  .redbox1, .yellowbox_1, .grrenbox_1 {
      min-height: 57px;
  }
  .add_1 {
      font-size: 14px;
  }
  .add_map {
      width: 22px;
      height: 22px;
      font-size: 13px;
  }
  .line-scale > div {
      height: 39px;
  }
  .reports_list .users_box {
      display: flex;
      padding: 5px 32px 5px 14px;
      margin-top: 5px;
  }
  .prescription-all-progress {
      justify-content: space-between;
  }
  header .navbar-light .navbar-nav .nav-link {
      font-size: 12px;
  }
  .username_row1 h6 {
      width: 64px;
      font-size: 12px;
  }
  .user_dropdown,  .user_dropdown img {
      width: 25px;
      height: 25px;
  }
  .active_menu:after {
      height: 1px;
  }
  .navbar-toggler {
      padding: 2px;
  }
  .right_side .grrenbox_1 h2, .right_side .yellowbox_1 h2, .right_side .redbox1 h2 {
      margin-bottom: 0 !important;
  }
  .nav-tabs .nav-link {
      font-size: 12px;
  }
  .group-content {
      margin-top: 11px !important;
  }
  .group-heading {
      font-size: 12px;
  }
  .group-box.group-box-new {
      height: 77px;
  }
  h3 {
      font-size: 20px;
  }
  h5 {
      font-size: 13px;
  }
  .social_icons_1 img {
      width: 32px;
  }
  .inner_box {
      min-height: 445px;
  }
  .points_details h4 {
      font-size: 17px;
  }
  .points_details p {
      font-size: 12px;
  }
  .inner_box .justify-content-center.w-100.row {
      margin: 0;
  }
  .bg-blue-box h3 {
      font-size: 18px;
  }
  .accptinvitation_btn {
      margin-top: 0 !important;
  }
  .accptinvitation_btn .btn {
      font-size: 13px;
  }
  .comp_details_head p {
      font-size: 13px;
  }
  .user_image_row1 {
      min-width: 100px;
      width: 100px;
      height: 100px;
  }
  .comp_details_area .details_label h3:first-child {
      font-size: 12px;
  }
  .details_rightside h3 {
      font-size: 13px;
  }
  .pointer-events-none {
      font-size: 12px;
  }
  .accpt_reject_section .inner_box {
      margin-top: 13px;
  }
  .accptbtn {
      padding: 7px 20px;
      color: #fff;
      width: initial;
      font-size: 13px;
  }
  .swal2-styled.swal2-confirm, .swal2-styled.swal2-cancel {
      font-size: 12px;
      padding: 10px 20px;
      border-radius: 32px;
  }
  .notification_sections .users_box {
      padding: 14px 36px 4px 17px;
  }
  .notification_sections .users_box, .notification_sections .users_box p {
      font-size: 12px;
  }
  .profile-page{
    padding: 0 !important;
  }
  .profile-inner a {
      font-size: 14px;
      padding: 7px 20px;
  }
  .user-profile-new {
      width: 120px;
      height: 120px;
  }
  .profile-image p {
      font-size: 14px;
      text-align: center;
  }
  .date-cart {
      font-size: 14px;
  }
  .upgrade-paln-btn {
      font-size: 14px;
  }
  .average-health h3 {
      font-size: 20px;
      margin-top: 13px;
  }
  .profile_tab .nav-link {
      padding: 5px 20px;
      font-size: 12px;
  }
  .precaution-modal-main p {
      font-size: 12px;
  }
  .search-form input {
      font-size: 12px;
  }
  .loginheader .profile_1.dropdown a {
      font-size: 12px;
  }
  .toggle_row h5 {
      font-size: 13px;
  }
  #swal2-content {
      font-size: 14px;
  }
  .switch-row h5 {
    font-size: 13px;
  }
  .justify-content-center.col-md-12 {
      margin: 0 !important;
      padding: 0;
  }
  .add-new-card {
      font-size: 13px;
  }
  .price_slider {
      margin-top: 13px;
  }
  .mood-range-slider {
      padding: 5px 30px;
  }
  .mood-range-slider h4 {
      font-size: 15px;
  }
  .profile-badge-header {
      left: 7px;
  }
  .dashboard_section {
      min-height: calc(772px - 160px);
  }
  label {
      font-size: 12px;
  }
  .form-control {
      font-size: 12px;
  }
  .btn-primary {
      font-size: 12px;
  }
  .edit_report, .del_report {
      font-size: 12px;
  }
  .react-share__ShareButton img {
      max-width: 70%;
  }
  .col-12 {
      font-size: 12px;
  }
  .col-md-12.text-center {
      font-size: 12px;
  }
  .metric_form.row h4, .metric_form select {
      font-size: 12px !important;
  }
  .timing-box li {
      min-width: 73px;
      font-size: 12px;
  }
  .timing-box {
      margin-bottom: 10px;
  }
  .metrics_row .btn.btn-link {
      position: relative;
      right: 27px;
      display: block;
      font-size: 12px !important;
  }
  .precaution-bt, .symptoms-bt {
      font-size: 13px;
  }
  .precaution-symptoms-bt p {
      padding: 18px 0;
  }
  .prescription-box.medical-history-box {
      margin-bottom: 0;
  }
  .img_row11 {
      padding: 0px 10px 0 76px;
  }
  .group-sub.joined {
      font-size: 12px;
  }
  .rightside_select {
      margin-bottom: 20px;
  }
  .web_mob1 {
    display: none;
  }
  .symptom-back{
    display: none;
  }
  .modal-content {
    width: 70%;
    margin: 0 auto;
  }
  .medi-time-text h5, .medi-time-text h6, .symtomps_list .listrow p {
    font-size: 12px;
  }
  .medi-time img {
    height: 50px;
  }
  .metric_form.row h3 {
    font-size: 18px;
  }
  .Toastify__toast-body > div:last-child {
    font-size: 12px;
  }
  .record_btn, .symtomps_list.active .tryagin_btn, .tryagin_btn, .stop_btn {
      padding: 4px 20px;
      font-size: 12px;
  }
  .nom_circle {
      width: 26px;
      height: 26px;
      font-size: 13px;
  }
  .sub_btn {
      font-size: 15px;
      padding: 5px 24px;
  }
  .border-0.bg-transparent.mx-1 img, .border-0.bg-transparent.mx-1 img {
      width: 49px;
  }
  .group-box.group-box-new .img_row11 {
      padding: 9px 10px 0 76px;
  }
  .swal2-popup {
      width: 23em;
  }
  .friend_sections .users_box {
      min-height: 46px !important;
  }
  .page-item .page-link {
      font-size: 12px;
  }
  .date-cart img {
      margin-right: 9px;
      width: 26px;
  }
}
@media (max-width: 400px) {
  .login-header .navbar {
      display: flex;
      align-items: center;
      justify-content: center !important;
  }
  .btn.bg-transparent.brder-0.camerabtn {
      padding: 40px !important; 
      width: 110px !important;
      height: 110px;
  }
  .reports_list .users_box {
      display: flex;
      padding: 5px 5px 5px 8px;
  }
  .prilfe_image {
      margin: 0 !important;
  }
  .login-btn {
      margin: 7px 0;
  }
  .login-header {
      padding-top: 5px !important;
      padding-bottom: 5px !important;
  }
  .right_side {
      padding: 21px !important;
  }
  .footer_social_links .list-group-item a {
      width: 30px;
      height: 30px;
  }
  .footer_social_links img {
      width: 14px;
  }
  .navbar-toggler {
      background: #fff;
      padding: 2px;
  }
  .mastership-plan .nav-tabs .nav-link {
      padding: 6px 3px;
      margin: 0 5px 0 4px;
      font-size: 11px;
  }
  .membership-top-right-content h5 {
      font-size: 18px;
  }
  .membership-tab-content ul li {
      font-size: 11px;
  }
  .membership-tab-content ul {
      padding-left: 12px;
  }
  .monthly-btn, .yearly-btn {
      padding: 8px 8px;
      height: 68px;
  }
  .monthly-btn h6, .yearly-btn h6 {
      font-size: 12px;
  }
  .monthly-btn h6 span, .yearly-btn h6 span {
      margin-left: 35px;
  }
  .info-icon {
      font-size: 13px;
  }
  .yearly-btn p {
      font-size: 10px;
      margin-left: 33px;
  }
  .affiliate-area p img {
      margin-right: 7px;
  }
  .affiliate-area p {
      font-size: 11px;
  }
  .business-license-contact-area form {
      padding-top: 0;
  }
  .business-license-contact-area form .form-control {
      padding: 7px 15px;
      font-size: 12px;
  }
  .business-license-contact-area form button {
      color: #fff !important;
      font-size: 12px;
      padding: 7px 38px;
      margin-top: 21px;
  }
  .leftside_tabing img {
      min-width: 32px;
      margin-right: 12px;
      display: block;
  }
  .leftside_tabing {
      display: flex;
  }
  .maintabing_row .left_side .nav-link {
      height: inherit;
  }
  .maintabing_row .left_side .nav-item:first-child .nav-link.active {
      border-radius: 0 !important;
  }
  .maintabing_row .left_side .nav-link.active, .maintabing_row .left_side .nav-item:last-child .nav-link.active {
      border-radius: 0 !important;
  }
  .dashboard_section {
      padding: 10px !important;
  }
  .maintabing_row .left_side .flex-column.nav.nav-pills .nav-item {
      width: 100%;
  }
  .right_side .grrenbox_1 h2, .right_side .yellowbox_1 h2, .right_side .redbox1 h2 {
      margin-bottom: 0 !important;
  }
}

@keyframes loading-1 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(144deg);
    transform: rotate(144deg);
  }
}
@keyframes loading-3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
  }
}
